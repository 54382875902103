<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-4" style="margin-left: 30%;">
				<div class="card">
					<div class="card-header">
						<h6>အပေါင် ပြန်ရွေး ရှာမည်</h6>
					</div>
					<div class="card-body">
						<form v-on:submit.prevent="onSubmit">
							<div class="form-group row" style="margin-left: 6%;">
								<div class="col-sm-3">
									<input type="radio" v-model="searchRequest.type" value="type1" class="form-check-input" id="exampleCheck1">
    								<label class="form-check-label" for="exampleCheck1">ပုံမှန်</label>
								</div>
								<div class="col-sm-3">
									<input type="radio" v-model="searchRequest.type" value="type2" class="form-check-input" id="exampleCheck2">
    								<label class="form-check-label" for="exampleCheck2">လဲ</label>
								</div>
								<!-- <div class="col-sm-3">
								 	<input type="radio" v-model="searchRequest.type" value="type3" class="form-check-input" id="exampleCheck3">
    								<label class="form-check-label" for="exampleCheck3">သွင်း</label>
								</div>
								<div class="col-sm-3">
								 	<input type="radio" v-model="searchRequest.type" value="type4" class="form-check-input" id="exampleCheck4">
    								<label class="form-check-label" for="exampleCheck4">ယူ</label>
								</div> -->
							</div>
							<div v-if="this.$role == 'admin'" class="form-group" style="text-align: center">
								<div class="col-sm-12">
									<select id="inputItemId" class="form-control" v-model="searchRequest.shopId">
										<option v-for="shop in shopList" :value="shop.id">{{shop.name}}</option>
									</select>
								</div>
							</div>
							<div class="form-group" style="text-align: center">
								<div class="col-sm-12">
									<input type="month" v-model="searchRequest.month" class="form-control" id="inputNo">
								</div>
							</div>
							<div class="form-group" style="text-align: center">
								<div class="col-sm-12">
									<select id="inputItemId" class="form-control" v-model="searchRequest.noType">
										<option value="">ဘောင်ချာ အမျိုးအစား ရွေးချယ်မည်</option>
										<option value="No">ဆိုင်ခွဲမရှိ</option>
										<option value="A">A</option>
										<option value="B">B</option>
									</select>
								</div>
							</div>
							<div class="form-group" style="text-align: center">
								<div class="col-sm-12">
									<input type="text" v-model="searchRequest.no" placeholder="ဘောင်ချာ အမှတ်" class="form-control" id="inputNo">
								</div>
							</div>
                            <div class="form-group" style="text-align: center">
								<div class="col-sm-12">
									<button type="submit" class="btn btn-primary" style="width: 100%;">ရှာမည်</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	import moment from 'moment';
	export default {
		name: 'mortgageSearch',
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				shopList: [],
                searchRequest: {
                    no: '',
					type: 'type1',
					shopId: '',
					noType: '',
					month: ''
                }
			}
		},
		watch: {
			async $route(to, from) {
				if (to.params.id) {
					this.mortgageRequest.id = to.params.id
					await this.itemDetail()
				}
			},
		},
		computed: {},
		methods: {
			...mapActions({
				mortgageClothFieldsAction: 'mortgageClothFieldsAction',
				mortgageClothSearchAction: 'mortgageClothSearchAction',
			}),
			async getFields() {
                let option = {
                    fields: {
                        type: "field"
                    }
                }
                await this.mortgageClothFieldsAction({
                    ...option
                }).then(res => {
					this.searchRequest.shopId = res.data.data.shop[0].id
                    this.shopList = res.data.data.shop
                }).catch(err => this.isLoading = true)
            },
			async onSubmit() {
				this.isLoading = true
				let option = this.searchRequest
				await this.mortgageClothSearchAction({
					...option
				}).then(res => {
                   
                    if ((res.data.status == 'success')) {
                        if(res.data.data.id){
							this.$router.replace({ path: 'cloth-mortgage-status', query: { id: res.data.data.id, type: res.data.data.filter_type}}).catch(()=>{})
                        }
                    }
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},

		},
		async mounted() {
			this.searchRequest.month = moment(new Date()).format('YYYY-MM')
			this.getFields()
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>